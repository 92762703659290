/**=====================
     74. internationalization css start
==========================**/
.hdg_main{
  position: relative;
}
.main {
  .langChoice{
    position: absolute;
    right: 40px;
    top: 31px;
    padding: 10px 12px;
    background-color: $transparent-color;
  }
}
.dropdown {
  .dropdown-toggle {
    background-color: rgba(115,102,255,0.08);
    color: $primary-color;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100px;
    text-align: left;
    &:after {
      position: absolute;
      top: 50%;
      right: 10px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "\f107";
      border: none;
      font-family: "FontAwesome";
      font-size: 18px;
      margin: 0;
    }
  }
  .dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89,102,122,0.1);
    box-shadow: 0 0 20px rgba(89,102,122,0.1);
    border: none;
    padding: 0;
    .dropdown-item {
      color: #2b2b2b;
      opacity: 0.6;
      font-size: 13px;
      padding: 6px 12px;
      border-top: 1px solid $light-semi-gray;
      background: $white;
      &:hover {
        background-color: $white;
      }
    }
  }
}
// responsive css 
@media (max-width: 991px) {
  .main{
    .langChoice {
      right: 20px;
      top: 11px;
      padding: 8px;
    }
  }
}

@media only screen and (max-width: 575px) {
   .main {
    .langChoice {
      top: 14px;
      padding: 4px 7px;
    }
  }
  .language-xs{
    margin-top: 15px;
  }
}

@media only screen and (max-width: 360px) {
   .language-header{
    padding-bottom: 60px !important;
  }
  .main  {
    .langChoice {
      left: 0;
      margin: 0 auto;
      top: 50px;
      width: 30%;
      right: 0;
    }
  }
}
/**=====================
     74. internationalization CSS end
==========================**/
